@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.button {
    @apply inline-flex items-center px-4 py-2 text-xs font-semibold tracking-widest text-gray-800 transition duration-150 ease-in-out bg-gray-100 border border-transparent rounded-md ring-gray-300;
}

.button:hover {
    @apply bg-gray-200;
}

.button:active, .button:focus {
    @apply bg-gray-300 ring;
}

.button.button-primary {
    @apply bg-blue-500 text-white !important;
}

.button-primary:hover, .button.button-primary:active, .button.button-primary:focus {
    @apply bg-blue-600 !important;
}

[x-cloak] {
    display: none !important;
}

/**
    Select2 tailwind
**/

.select2-container {
	/* The container where the selectbox is housing*/
	@apply box-border relative inline-block m-0 mb-2;
}

.select2-container .select2-selection--single {
    /* Selection box itself */
    @apply box-border block border rounded shadow cursor-pointer select-none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    /* DIV inside Selection box with text, clear button and arrow down*/
    @apply block h-6 pl-1 pr-6 truncate;
}

.select2-container .select2-selection--single .select2-selection__clear {
    /* The DIV where the X is housing to clear the chosen option */
    @apply relative -m-1;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    /*@apply;*/
}

.select2-container .select2-search--inline {
    /* Search box*/
    @apply float-left;
}
.select2-container .select2-search--inline .select2-search__field {
    @apply box-border p-0 mt-1 text-base border;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    @apply appearance-none;
}

.select2-dropdown {
	/* Dropdown area when the arrow down is clicked */
    @apply box-border absolute z-50 block float-left w-auto bg-white border border-indigo-600 border-solid;
}

.select2-results {
	@apply block;
}

.select2-results__options {
	@apply p-0 m-0 list-none;
}

.select2-results__option {
	/* The rows with results that you see after dropdown.
	Increase p-1 to p-2 to have more space between results */
	@apply p-2 select-none;
}

.select2-results__option[aria-selected] {
    @apply cursor-pointer;
}

.select2-container--open .select2-dropdown {
	/* Dropdown container opened and results are shown*/
	@apply left-0 p-1 mt-1;
}

.select2-container--open .select2-dropdown--above {
	/* The left and right borders of the option rows */
	@apply border-indigo-400 rounded shadow;
}

.select2-container--open .select2-dropdown--below {
	/* The left and right borders of the option rows */
	@apply border-indigo-400 rounded shadow;
}

.select2-search--dropdown {
	/* Search dropdown element*/
	@apply block p-2;
}

.select2-search--dropdown .select2-search__field {
    /* Search box itself where you can enter text*/
    @apply box-border h-10 p-1 border-2 border-blue-300 rounded outline-none;
    width: 100%;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    @apply appearance-none;
}

.select2-search--dropdown.select2-search--hide {
	@apply hidden;
}

.select2-close-mask {
	@apply block w-12 min-w-full p-0 m-0;
	border: 0;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: #fff;
	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}


/*
	Default template settings:
*/

.select2-container--default .select2-selection--single {
	/* Selection bar - Self */
	@apply h-10 p-2 bg-white border border-gray-400 border-solid;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    /* Selection bar - Text color of the item you selected from the results */
    @apply text-gray-700;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    /* Selection bar - Clear button - If this property is enabled*/
    @apply cursor-pointer mt-0.5 mr-8 float-right text-red-700;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    /* Selection bar - Color of the placeholder text before selection - If this property is enabled */
    @apply text-gray-600;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    /* Selection bar - DIV where the arrow pointing down is living*/
    @apply absolute top-0 right-0 w-8 h-10;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    /* Arrow is a CSS triangle that does not exists in Tailwind without a package */
    @apply absolute w-0 h-0 border-t-4 border-b-0 border-l-4 border-r-4 border-solid;
    border-color: #000 transparent transparent transparent;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    top: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
	/* Selection bar - Clear button - If this property is enabled from right to left*/
	@apply float-left ml-4;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	/* Placement of the dropdown arrow when in rtl mode */
	@apply left-0 right-auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
	/* Selection by property disabled*/
	@apply bg-gray-300 cursor-default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    /* Selection bar - Hide the clear cross when selection bar is disabled*/
    @apply hidden;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #888 transparent;
	border-width: 0 4px 5px 4px;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
	@apply hidden;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	/* The border of the search textbox */
	@apply border-solid;
}

.select2-container--default .select2-search--inline .select2-search__field {
	/* Search textbox */
    @apply bg-transparent border-none shadow-none outline-none select-text;
}

.select2-container--default .select2-results > .select2-results__options {
	/* Hight of the dropdown zone where the options or results are visible */
	@apply h-64 overflow-y-auto;
}

.select2-container--default .select2-results__option[role=group] {
	/* No clue what this does */
	@apply p-0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
	@apply text-gray-500;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	/* The already selected option row color */
	@apply bg-gray-400;
}

.select2-container--default .select2-results__option .select2-results__option {
	padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	/* Background color and text color of the options rows when select is open */
	@apply text-white bg-blue-500;
}

.select2-container--default .select2-results__group {
	/* Have no idea what this is for */
    @apply block cursor-default;
    padding: 6px;
}

/*********************/
/* PACE PROGRESS BAR */
/*********************/
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: #50b948;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 4px;
}

.pace .pace-progress-inner {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #50b948, 0 0 5px #50b948;
	opacity: 1.0;
	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-moz-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	-o-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
	display: block;
	position: fixed;
	z-index: 2000;
	top: 15px;
	right: 15px;
	width: 14px;
	height: 14px;
	border: solid 2px transparent;
	border-top-color: #50b948;
	border-left-color: #50b948;
	border-radius: 10px;
	-webkit-animation: pace-spinner 400ms linear infinite;
	-moz-animation: pace-spinner 400ms linear infinite;
	-ms-animation: pace-spinner 400ms linear infinite;
	-o-animation: pace-spinner 400ms linear infinite;
	animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes pace-spinner {
	0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes pace-spinner {
	0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes pace-spinner {
	0% { -ms-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes pace-spinner {
	0% { transform: rotate(0deg); transform: rotate(0deg); }
	100% { transform: rotate(360deg); transform: rotate(360deg); }
}
